import { postForm } from "./api";

export default class Form {
	#form;
	#inputs;
	initPlaceholders = {};

	constructor(formRef) {
		this.#form = document.querySelector(formRef);

		if(!this.#form) return;

		this.#inputs = [...this.#form.querySelectorAll("input"), ...this.#form.querySelectorAll("textarea")];
		[].slice.call(this.#inputs).map(input => this.initPlaceholders[input.name] = input.placeholder);

		this.#form.querySelector("form").addEventListener("submit", this._onSubmit.bind(this));
		this.#inputs.forEach(input => input.addEventListener("focus", () => {
      input.classList.remove("error");
      input.placeholder = this.initPlaceholders[input.name];
    }));
	}

	_errorHandler(result) {
		this.#inputs.forEach(input => {
			if (result.errors && result.errors[input.name]) {
				input.value = "";
				input.placeholder = result.errors[input.name];
				input.classList.add("error");
			}
		});
	}

	_successHandler() {
		this.#inputs.forEach(input => {
			input.placeholder = this.initPlaceholders[input.name];
			input.classList.remove("error");
		});

		window.location.replace("#success-modal");
		setTimeout(() => window.location.replace("#closed-modal"), 2000);
	}

 	_onSubmit(e) {
		e.preventDefault();

		const data = new FormData();

		this.#inputs.map(input => data.append(input.name, input.value));

		postForm(e.target.action, data, this._successHandler.bind(this), this._errorHandler.bind(this));
	}
}