export default class Tabs {
	#tabs;
	#tabsContent;
	#tabsContainer;
	#isSeveralAllowed;
	#tabBtnName;
	#tabContentName;
	
	constructor(tabsRef, isSeveralAllowed = false, btnName = "data-js-tab-btn", contentItemName = "data-js-tab-content") {
		this.#tabsContainer = document.querySelector(tabsRef);
		if(!this.#tabsContainer) return;

		this.#tabBtnName = btnName;
		this.#tabContentName = contentItemName;

		this.#tabs = this.#tabsContainer.querySelectorAll(`[${btnName}]`);
		this.#tabsContent = this.#tabsContainer.querySelectorAll(`[${contentItemName}]`);
		this.#isSeveralAllowed = isSeveralAllowed;

		this.#tabsContainer.addEventListener("click", this._changeTab.bind(this));
	}

	_changeTab(e) {
		const clicked = e.target.closest(`[${this.#tabBtnName}]`);

		// Guard clause
		if (!clicked) return;
	
		// Remove active classes
		if(!this.#isSeveralAllowed) {
			this.#tabs.forEach(t => t.classList.remove("active"));
			this.#tabsContent.forEach(c => c.classList.remove("active"));

			// Activate tab
			clicked.classList.add("active");
		
			// Activate content area
			this.#tabsContainer
				.querySelector(`[${this.#tabContentName}="${clicked.dataset.jsTabBtn}"]`)
				?.classList.add("active");
		} else {
			// Activate tab
			console.log(clicked.dataset.forOpenOnly);
			if(clicked.dataset.forOpenOnly) {
		
				// Activate content area
				this.#tabsContainer
					.querySelector(`[${this.#tabContentName}="${clicked.dataset.jsTabBtn}"]`)
					?.classList.add("active");

				return;
			}
			
			clicked.classList.toggle("active");
		
			// Activate content area
			this.#tabsContainer
				.querySelector(`[${this.#tabContentName}="${clicked.dataset.jsTabBtn}"]`)
				?.classList.toggle("active");
		}
	}
}