export default class Modal {
	#modal;
	#heading;
	#subheading;
	#initHeading;
	#initSubheading;

	constructor(modalRef, btnsRef) {
		this.#modal = document.querySelector(modalRef);

		this.#heading = this.#modal.querySelector("h2");
		this.#subheading = this.#modal.querySelector("p");

		
		this.#initHeading = this.#heading.innerHTML;
		this.#initSubheading = this.#subheading.innerHTML;

		if(!this.#modal) return;

		console.log(btnsRef)

		this.#modal.closest("[data-modal-background]")?.addEventListener("click", this._closeModal.bind(this));
		this.#modal.querySelector("[data-modal-close-btn]")?.addEventListener("click", this._closeModal.bind(this));
		this.#modal.querySelector("[data-modal-window]").addEventListener("click", (e) => { e.stopPropagation(); });

		console.log(document.querySelectorAll(btnsRef));

		document.querySelectorAll(btnsRef)?.forEach(btn => btn.addEventListener("click", this._changeModal.bind(this)));
	}

	_closeModal() {
		console.log("close");
		window.location.href = `#closed-modal`;

		setTimeout(() => {
			this.#heading.innerHTML = this.#initHeading;
			this.#subheading.innerHTML = this.#initSubheading;
		}, 1000);
	}

	_changeModal(e) {
		this.#heading.innerHTML = e.target.closest(".product-card__caption").querySelector("h3").innerHTML;
		this.#subheading.innerHTML = e.target.closest(".product-card__caption").querySelector("h4").innerHTML;
		this.#modal.querySelector("[name='product']").value = this.#heading.innerHTML;
	}
}