export async function postForm(uri, data, callback, fallback) {

  try {
    const response = await fetch(uri, {
      method: "POST",
      body: data
    });

    const result = await response.json();
    
    if (!response.ok) throw new Error(response.status);

    if (result.status === "error") {
      fallback(result);
    } else {
      callback();
    }
    
  } catch(err) {
    console.log(err);
  }
}