export function MobileMenu() {
  const navBtn = document.querySelector("[data-nav-btn]");
  const navMenu = document.querySelector("[data-nav-menu]");

  navBtn.addEventListener("click", () => {
    navBtn.classList.toggle("active");
    navMenu.classList.toggle("active");
  });

  navMenu.addEventListener("click", e => {
    const clicked = e.target.closest("a");
    
    // Guard clause
    if (!clicked) return;
    
    navBtn.classList.remove("active");
    navMenu.classList.remove("active");
    
  });
}